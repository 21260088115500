

































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';

import _ from 'lodash';

import FormMixin from '@mixins/local/form.mixin';
import { reset } from '@services/models/forgotten-password.model';
import { ComponentOptions } from 'vue';
import { MutationOptions } from 'apollo-client';
import { ExtRecord } from '@interfaces/global.interface';

import gql from 'graphql-tag';
import { RouteRecord } from 'vue-router';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_forgotten_password_reset',
        });
    },
};

@Component(def as ComponentOptions<Vue>)
export default class ResetPassword extends FormMixin {
    @Prop({ type: String }) readonly uuid!: string;

    protected customSuccessMessage = 'web.success_reset_pass';
    protected mutation = 'resetPassword';

    public confirmation = '';

    created(): void {
        this.init<typeof reset>(reset);
    }

    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return {
            // Query
            mutation: gql`
                mutation ResetPassword($password: String, $uuid: String, $token: String) {
                    resetPassword(password: $password, uuid: $uuid, token: $token) {
                        status
                        message
                    }
                }
            `,
            // Parameters
            variables: {
                password: model.password,
                uuid: model.uuid,
                token: model.token,
            },
        };
    }

    public async onLocalSubmit(): Promise<unknown> {
        this.model.token = await this.recaptcha();
        this.model.uuid = this.uuid;
        this.onSubmit();

        return true;
    }

    /**
     * Call after submit
     */
    protected afterSubmitHook(result: ExtRecord<string | number> | null | undefined): void {
        const { meta } = _.last(this.$route.matched) as RouteRecord;

        if ('afterSubmitRoute' in meta) {
            this.$router.push({ name: meta.afterSubmitRoute });
        }
    }
}
