












































// eslint-disable-next-line max-classes-per-file
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { meta } from '@/services/meta.service';

import MODEL from '@services/models/product-activation.model';
import { product, templates } from '@interfaces/gql/products';
import { ComponentOptions } from 'vue';
import PageMixin from '@mixins/local/page.mixin';

import RelatedProduct from '@components/products/RelatedProducts.vue';
import { ExtRecord } from '@interfaces/global.interface';
import { MutationOptions } from 'apollo-client';
import gql from 'graphql-tag';
import _ from 'lodash';
import { ProductConst } from '@/store/product';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_product_confirmation_success',
        });
    },
};

@Component(def as ComponentOptions<Vue>)
export default class SuccessConfirmation extends Mixins(PageMixin) {}
