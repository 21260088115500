








import { Component, Vue } from 'vue-property-decorator';

@Component({
    computed: {
        name() {
            return this.$store.state.demo.test;
        },
    },
})
export default class DemoPage extends Vue {}
