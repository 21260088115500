/**
 *  Storage service
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */
export class StorageService {
    public isSupported(): boolean {
        return 'localStorage' in window;
    }

    public set<T>(key: string, value: T): void {
        if (!this.isSupported()) {
            return;
        }
        let val = '';
        if (value) {
            val = JSON.stringify(value);
        }
        localStorage.setItem(key, val);
    }

    public get<T>(key: string): T | null {
        if (!this.isSupported()) {
            return null;
        }
        const Value: string | null = localStorage.getItem(key);

        if (Value && Value !== 'undefined' && Value !== 'null') {
            return JSON.parse(Value) as T;
        }

        return null;
    }

    /**
     * Check if key exists
     * @param  {string}  key
     * @return {boolean}
     */
    public isset<T>(key: string): boolean {
        if (!this.isSupported()) {
            return false;
        }
        return this.get<T>(key) !== null;
    }

    public remove(key: string): void {
        if (!this.isSupported()) {
            return;
        }
        localStorage.removeItem(key);
    }
}

export const storage = new StorageService();
