














import { Component, Vue, Model } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import { mixins } from 'vue-class-component';

import InputTypeMixin from '@mixins/local/inputtype.mixin';

@Component
export default class AlgSelect extends InputTypeMixin {
    @Model('change') readonly modelx!: any;

    public lModel = this.modelx;

    created() {}

    public getLabel(item: Record<string, string | number>) {
        if (this.isLabelCode) {
            return item.code;
        }
        return item.name;
    }

    public onChange(val: string | number): void {
        this.$emit('change', val);
    }
}
