import _ from 'lodash';

/**
 * Number formating
 * @see https://appmakers.dev/bcp-47-language-codes-list/
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
 */
export const number = (value: number, locale = 'cs-CZ', options = { style: 'decimal', currency: 'CZK' }): string => {
    if (!_.isNumber(value)) {
        return value;
    }
    return new Intl.NumberFormat(locale, options).format(value);
};
