/**
 *  Base Page mixin
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import Vue from 'vue';
import Component from 'vue-class-component';
import CONFIG from '@config/app.config';
import { CategoryTypes } from '@interfaces/products';
import { ApolloError } from 'apollo-client';
import gql from 'graphql-tag';

@Component
export default class PageMixin extends Vue {
    public isError = false;

    public contact = { message: '' };

    /**
     * Is data loading
     */
    get isLoading(): boolean {
        return this.$apollo.loading;
    }

    public getIcon(name: CategoryTypes): string {
        return CONFIG.categoryIcon[name];
    }

    public getClass(name: CategoryTypes, className: string): Record<string, boolean> {
        const state = (CONFIG.categoryClass[name] as Record<string, boolean>)[className];
        return { [className]: state };
    }

    protected gqlError(trans: string, error: ApolloError) {
        if (!this.isError) {
            this.$message.error(this.$t(trans) as string);
            this.isError = true;
        } else {
            this.isError = false;
        }
    }

    public async createEmptyLead(): Promise<void> {
        try {
            await this.$apollo.mutate({
                // Query
                mutation: gql`
                    mutation EmptyLead($message: String = "") {
                        emptyLead(message: $message) {
                            status
                            message
                        }
                    }
                `,

                variables: {
                    message: this.contact?.message ?? '',
                },
            });

            const message = this.$t('web.empty_lead_success') as string;
            const title = this.$t('web.message_success') as string;

            this.$notify({
                title,
                message,
                type: 'success',
                duration: 10000,
            });
        } catch (e) {
            this.$message({
                type: 'error',
                message: this.$t('web.lead_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string,
                duration: 12000,
            });
        }
    }
}
