













































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';

import SocialSignInComponent from '@/components/general/SocialSignInComponent.vue';
import { Getter, Mutation } from 'vuex-class';

import _ from 'lodash';

import FormMixin from '@mixins/local/form.mixin';
import MODEL from '@services/models/login.model';
import { ComponentOptions } from 'vue';
import { MutationOptions } from 'apollo-client';
import { ExtRecord } from '@interfaces/global.interface';
import { Login as UserLogin } from '@interfaces/form.interface';
import gql from 'graphql-tag';
import { AuthConst } from '@store/auth';
import http from '@services/http.client';
import { User } from '@interfaces/auth.interface';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_login',
        });
    },
    components: {
        socialSignIn: SocialSignInComponent,
    },
};

Component.registerHooks(['beforeRouteEnter']);

@Component(def as ComponentOptions<Vue>)
export default class Login extends FormMixin {
    @Getter('auth/isNotAuth') readonly getterNotAuth!: boolean;
    @Mutation(`auth/${AuthConst.TOGGLE_NOTAUTH}`) readonly mutationNotAuth!: (payload: boolean) => void;
    @Mutation(`auth/${AuthConst.AUTH_SUCCESS}`) readonly mutationAuthSuccess!: (payload: User) => void;
    @Mutation(`auth/${AuthConst.SIGN_OUT}`) readonly mutationSignOut!: () => void;

    protected customSuccessMessage = 'web.success_signin';

    protected mutation = 'signin';

    created(): void {
        this.init<typeof MODEL>(MODEL);

        if (!_.isUndefined(this.$route.query?.googlefailed) || !_.isUndefined(this.$route.query?.facebookfailed)) {
            this.$message.error({ message: this.$t('web.sign_social_failed') as string, duration: 8000 });
        }
    }

    get isNotAuth(): boolean {
        return this.getterNotAuth;
    }

    public async onSubmit() {
        try {
            await this.signIn(this.model);
            this.$router.push({ name: 'Dashboard' });
        } catch (e) {
            this.mutationSignOut();
            if (e.status === 401) {
                this.$message.error(this.$t('web.form.login_error') as string);
            } else {
                this.$message.error(this.$t('web.form.server_error') as string);
            }
        } finally {
            this.finish();
        }
    }

    protected beforeSubmitHook(model: ExtRecord<string | number>): void {
        this.mutationNotAuth(false);
    }

    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return {
            // Query
            mutation: gql`
                mutation Signin($input: SignInput) {
                    signin(identity: $input) {
                        status
                        message
                    }
                }
            `,
            // Parameters
            variables: {
                input: model,
            },
        };
    }
}
