/**
 *  i18n translations config
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 *
 */

import { Locale } from '@interfaces/i18n.interface';

// Avail languages iso codes 639-1
export const AVAIL_LANGS = [Locale.Cs, Locale.En];
export const MAIN_LANG = Locale.Cs;

// Locale trans
export const localeTrans: Record<Locale, string> = {
    [Locale.Cs]: 'Čeština',
    [Locale.En]: 'English',
};
