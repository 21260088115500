import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteRecord, RouterOptions } from 'vue-router';

import modulesInit from '@/modules/modules.init';

import PAGES_ROUTE from '@pages/pages.router';

import store from '@store/index';
import _ from 'lodash';
import { AuthConst } from '@store/auth';

Vue.use(VueRouter);

const options: RouterOptions = {
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...modulesInit.router, ...PAGES_ROUTE],
};

const router = new VueRouter(options);

export default router;

// TODO just template implement real auth

async function authCeck(route: Route, next: NavigationGuardNext<Vue>): Promise<void> {
    const {
        meta: { requiresAuth = false },
    } = _.last(route.matched) as RouteRecord;

    try {
        const user = await store.dispatch('auth/status');
        store.commit(`auth/${AuthConst.AUTH_SUCCESS}`, user);
        if (!requiresAuth) {
            next({ name: 'Dashboard' });
        }
    } catch (e) {
        store.commit(`auth/${AuthConst.SIGN_OUT}`);
        if (requiresAuth) {
            store.commit(`auth/${AuthConst.TOGGLE_NOTAUTH}`, true);
            next({ name: 'Login' });
        }
    }
}

router.beforeEach(async (to, from, next) => {
    await authCeck(to, next);
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters['auth/isAuth']) {
            store.commit(`auth/${AuthConst.TOGGLE_NOTAUTH}`, true);
            next({
                name: 'Login',
                query: { redirect: to.fullPath },
            });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});
