

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import { mixins } from 'vue-class-component';

import InputTypeMixin from '@mixins/local/inputtype.mixin';

@Component
export default class AlgInputContainer extends Vue {
    @Prop({ default: '' }) readonly prop!: string;

    @Prop({ default: '' }) readonly label!: string;

    @Prop({ default: '' }) readonly placeholder!: string;

    @Prop({ type: Array }) readonly errors!: string[];

    @Prop({ type: Object }) readonly classes!: typeof Object;

    @Prop({ type: Boolean, default: false }) readonly required: boolean = false;
}
