/**
 *  FRegistration model
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { Registration } from '@interfaces/form.interface';

const Model: Registration = {
    name: '',
    surname: '',
    company: '',
    email: '',
    phoneCode: '+420',
    phone: null,
    password: '',
    token: null,
    agree: false,
};

export default Model;
