












































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import { mixins } from 'vue-class-component';

import FormMixin from '@mixins/local/form.mixin';
import MODEL from '@services/models/forgotten-password.model';
import { ComponentOptions } from 'vue';
import { MutationOptions } from 'apollo-client';
import { ExtRecord } from '@interfaces/global.interface';
import { Login as UserLogin } from '@interfaces/form.interface';
import { AuthConst } from '@store/auth';
import http from '@services/http.client';
import { User } from '@interfaces/auth.interface';
import gql from 'graphql-tag';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_forgotten_password',
        });
    },
};

@Component(def as ComponentOptions<Vue>)
export default class ForgottenPassword extends FormMixin {
    protected customSuccessMessage = 'web.success_resend_pass';
    protected mutation = 'forgottenPassword';

    created(): void {
        this.init<typeof MODEL>(MODEL);
    }

    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return {
            // Query
            mutation: gql`
                mutation ForgottenPassword($email: String, $token: String) {
                    forgottenPassword(email: $email, token: $token) {
                        status
                        message
                    }
                }
            `,
            // Parameters
            variables: {
                email: model.email,
                token: model.token,
            },
        };
    }

    public async onLocalSubmit(): Promise<unknown> {
        this.model.token = await this.recaptcha();
        this.onSubmit();
        return true;
    }
}
