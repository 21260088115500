import Vue from 'vue';

export default Vue.extend({
    computed: {
        /**
         * Get App version
         * @return {string}
         */
        version(): string | undefined {
            return process.env.VUE_APP_VERSION;
        },
    },
    methods: {
        facebook(): void {
            window.location.href = `${process.env.VUE_APP_SERVER_URL}api/user/facebook`;
        },

        google(): void {
            window.location.href = `${process.env.VUE_APP_SERVER_URL}api/user/google`;
        },
    },
});
