/**
 *  Product schema
 *  @module schema
 *  @see https://graphql.org/learn/schema/
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import gql from 'graphql-tag';

export const productFragment = `   
    fragment productFields on Product {
        id
        name
        name_normalize
        support_name
        discount_price
        short_description_c
    }
`;

export const categories = gql`
    query Categories($onlyActive: Boolean = true) {
        categories(active: $onlyActive) {
            id
            name
            name_normalize
        }
    }
`;

export const templates = gql`
    query Products($onlyActive: Boolean = true) {
        products(active: $onlyActive) {
            category_id
            ...productFields
        }
    }
    ${productFragment}
`;

export const product = gql`
    query Product($pid: String) {
        product(pid: $pid) {
            ...productFields
            category_name_normalize
            category_id
            description
        }
    }
    ${productFragment}
`;
