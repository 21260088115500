



















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class User extends Vue {
    mounted(): void {
        window.addEventListener(
            'message',
            (event) => {
                /* eslint-disable */
                console.log('GET MESSAGE', event.data);
                /* eslint-enable */
            },
            false
        );
    }
}
