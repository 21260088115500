


































































// eslint-disable-next-line max-classes-per-file
import { Component, Ref } from 'vue-property-decorator';
import PageMixin from '@mixins/local/page.mixin';
import _ from 'lodash';
import gql from 'graphql-tag';
import { ValidationObserver } from 'vee-validate';

@Component
export default class Contact extends PageMixin {
    @Ref('formContact') readonly formObserver!: InstanceType<typeof ValidationObserver>;

    @Ref('ruleForm') readonly ruleForm!: any;

    public isMessageSending = false;

    public async sendMessage(): Promise<void> {
        try {
            this.isMessageSending = true;

            await this.createEmptyLead();

            this.contact.message = '';
            this.ruleForm.resetFields();
            setTimeout(() => this.formObserver.reset());
        } catch (e) {
            this.$message({
                type: 'error',
                message: this.$t('web.lead_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string,
                duration: 12000,
            });
        } finally {
            this.isMessageSending = false;
        }
    }

    public async sendMessageOld(): Promise<void> {
        try {
            this.isMessageSending = true;
            const {
                data: {
                    message: { status },
                },
            } = (await this.$apollo.mutate({
                // Query
                mutation: gql`
                    mutation ContactMessage($message: String) {
                        message(text: $message) {
                            status
                            message
                        }
                    }
                `,
                variables: {
                    message: this.contact.message,
                },
            })) as { data: { message: { status: boolean } } };
            if (!status) {
                throw new Error('Message status error');
            }
            const message = this.$t('web.empty_lead_success') as string;
            const title = this.$t('web.message_success') as string;
            this.$notify({
                title,
                message,
                type: 'success',
                duration: 10000,
            });

            this.contact.message = '';
            this.ruleForm.resetFields();
            setTimeout(() => this.formObserver.reset());
        } catch (e) {
            this.$message({
                type: 'error',
                message: this.$t('web.lead_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string,
                duration: 12000,
            });
        } finally {
            this.isMessageSending = false;
        }
    }
}
