











































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ProductTemplate, ProductCategory } from '@interfaces/products';
import PageMixin from '@mixins/local/page.mixin';

@Component
export default class Product extends PageMixin {
    @Prop({ type: Object }) public product!: ProductTemplate;
    @Prop({ type: String }) public cgName!: string;

    get productInfo(): string[] {
        return this.product?.short_description_c?.split('-') ?? [];
    }
}
