














import { Component, Vue } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import { ComponentOptions } from 'vue';
import FormMixin from '@mixins/local/form.mixin';

const def = {
    metaInfo() {
        return meta({
            title: 'web.contact',
        });
    },
};

@Component(def as ComponentOptions<Vue>)
export default class Contact extends FormMixin {}
