/**
 *  Auth interfaces
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

export enum Type {
    LOCAL = 'local',
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
}

export interface User {
    readonly name: string | Record<string, string>;
    readonly surname?: string;
    readonly email?: string;
    readonly displayName?: string;
    readonly type: Type;
    readonly phone: string;
    readonly phoneCode: string;
    readonly emails: { value: string }[];
    readonly profile: { name?: string; email?: string };
}
