

















































import { Component, Vue } from 'vue-property-decorator';

import { meta } from '@services/meta.service';

import { ComponentOptions } from 'vue';

import Registration from './Index.vue';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_registration',
        });
    },
    components: {
        registration: Registration,
    },
};
@Component(def as ComponentOptions<Vue>)
export default class SimpleRegistration extends Vue {
    created(): void {}
}
