













































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import { SmartQuery } from 'vue-apollo-decorators';
import { ComponentOptions } from 'vue';
import PageMixin from '@mixins/local/page.mixin';
import { orders } from '@interfaces/gql/orders';
import { Order, OrderState, OrderType } from '@interfaces/orders';
import gql from 'graphql-tag';
import moment from 'moment';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_ordered',
        });
    },
};

@Component(def as ComponentOptions<Vue>)
export default class Ordered extends PageMixin {
    @SmartQuery<Ordered>({
        query: orders,
        fetchPolicy: 'no-cache',
        loadingKey: 'oid',
        variables() {
            return {};
        },
        error(error) {
            this.gqlError('web.messages.orders_load_error', error);
        },
    })
    public orders: Order[] | null = null;

    public text = '';

    public dialogVisible = false;

    public messageLoading = false;

    public selectedOid: string | null = null;

    public onMessage(oid: string): void {
        this.dialogVisible = true;
        this.selectedOid = oid;
    }

    public resetDialog() {
        this.dialogVisible = false;
        this.selectedOid = null;
        this.messageLoading = false;
    }

    public async sendMessage(): Promise<void> {
        try {
            this.messageLoading = true;
            await this.$apollo.mutate({
                // Query
                mutation: gql`
                    mutation OnMessahe($input: MessageInput) {
                        orderMessage(message: $input) {
                            status
                            message
                        }
                    }
                `,
                // Parameters
                variables: {
                    input: {
                        text: this.text,
                        oid: this.selectedOid,
                    },
                },
            });

            this.$message({
                message: this.$t('web.order_message_success') as string,
                type: 'success',
                duration: 5000,
            });
        } catch (e) {
            this.$message.error(
                this.$t('web.form.server_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string
            );
        } finally {
            this.resetDialog();
        }
    }

    public activeTo(unixTime: number): string {
        return moment(unixTime).add(14, 'd').format('D.M. YYYY');
    }

    public isActivePass(unixTime: number): boolean {
        const demoDate = moment(unixTime).add(14, 'd');

        return demoDate.isBefore(moment());
    }

    public isTrial(type: OrderType): boolean {
        return type === OrderType.TRIAL;
    }

    public isNew(state: number): boolean {
        return state === OrderState.NEW;
    }

    public isActive(state: number): boolean {
        return state === OrderState.ACTIVE;
    }

    public isDemoExpired(state: number): boolean {
        return state === OrderState.DEMO_EXPIRED;
    }

    public isActiveProduct(state: number): boolean {
        return state === OrderState.ACTIVE_PRODUCT;
    }
}
