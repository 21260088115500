var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(false),expression:"false"}],staticClass:"login-page alg-login"},[(_vm.isNotAuth)?_c('el-alert',{staticClass:"mb-3",attrs:{"title":_vm.$t('web.not_auth_alert'),"data-cy":"notAuth-alert","show-icon":"","type":"warning","effect":"dark"}}):_vm._e(),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{ref:"form",attrs:{"model":_vm.model,"label-width":"120px","label-position":"top"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col alg-h1 mb-2"},[_vm._v(_vm._s(_vm.$t('web.signin_title')))])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mb-4 user-h1"},[_vm._v(" "+_vm._s(_vm.$t('web.welcome_main'))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"email","label":_vm.$t('web.email'),"errors":errors,"classes":classes,"required":required,"placeholder":"@"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col no-margin"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.password'),"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"password","label":_vm.$t('web.password'),"errors":errors,"classes":classes,"password":true,"required":required},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}],null,true)}),_c('div',{staticClass:"text-right"},[_c('router-link',{staticClass:"link-small",attrs:{"to":"/forgotten-password"}},[_vm._v(_vm._s(_vm.$t('web.forgotten_password')))])],1)],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('el-button',{staticClass:"big-button",attrs:{"type":"primary","loading":_vm.loading,"disabled":invalid,"data-cy":"signin-submit"},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('web.signin_action'))+" ")])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col mt-4 mb-2 text-center"},[_c('div',{staticClass:"link-separator w-100"},[_c('span',{staticClass:"link-separator__text"},[_vm._v(_vm._s(_vm.$t('web.or')))])])])]),_c('social-sign-in'),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left link-small"},[_vm._v(" "+_vm._s(_vm.$t('web.no_account'))+" "),_c('router-link',{attrs:{"to":{ name: 'Registration' }}},[_c('strong',[_vm._v(_vm._s(_vm.$t('web.make_registration')))])])],1)]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col text-center"},[_c('app-lang')],1)])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }