/**
 *  Router
 *  @module DemoModule
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2019 Algotech a.s.
 */

import DemoPage from './pages/demo-page/Index.vue';

export default [
    {
        path: '/demo',
        name: 'module-demo-page',
        component: DemoPage,
        meta: { requiresAuth: true },
    },
];
