










































































































































































































































import { Component, Vue, Mixins, Prop } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';

import FormMixin from '@mixins/local/form.mixin';
import MODEL from '@services/models/registration.model';
import DIAL_CODES from '@config/dial-codes.config';
import { ExtRecord } from '@interfaces/global.interface';
import SocialSignInComponent from '@/components/general/SocialSignInComponent.vue';
import _ from 'lodash';
import gql from 'graphql-tag';
import { RouteRecord } from 'vue-router';
import { MutationOptions } from 'apollo-client';
import { ComponentOptions } from 'vue';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_registration',
        });
    },
    components: {
        socialSignIn: SocialSignInComponent,
    },
};
@Component(def as ComponentOptions<Vue>)
export default class Registration extends Mixins(FormMixin) {
    @Prop({ type: Boolean, default: false }) readonly simple!: boolean;

    public phoneCodes: Record<'code' | 'name', string>[] = DIAL_CODES;

    public confirmation = '';

    public checked = false;

    protected mutation = 'registration';

    public hello = '';

    private internalSimple = true;

    protected customSuccessMessage: string | null = 'web.form.submit_success_registration';

    created(): void {
        this.init<typeof MODEL>(MODEL);
    }

    mounted() {
        const w = window.matchMedia('(max-width: 600px)').matches;

        if (w) {
            this.internalSimple = false;
        }

        window.addEventListener('resize', () => {
            const w = window.matchMedia('(max-width: 600px)').matches;
            this.internalSimple = true;
            if (w) {
                this.internalSimple = false;
            }
        });
    }

    get iSimple(): boolean {
        return this.simple && this.internalSimple;
    }

    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return {
            // Query
            mutation: gql`
                mutation Registration($input: RegistrationInput) {
                    registration(identity: $input) {
                        status
                        message
                    }
                }
            `,
            // Parameters
            variables: {
                input: model,
            },
        };
    }

    public async onLocalSubmit(): Promise<unknown> {
        this.model.token = await this.recaptcha();
        this.onSubmit();
        return true;
    }

    protected beforeSubmitHook(model: ExtRecord<string | number>): void {
        delete model.agree;
    }

    /**
     * Call after submit
     */
    protected async afterSubmitHook(result: ExtRecord<string | number> | null | undefined): Promise<void> {
        this.tagManager();

        // Post message Registration success
        const target = window.opener || window.top;
        target.postMessage({ source: 'algoportal', action: 'Registration success' }, '*');

        const { meta } = _.last(this.$route.matched) as RouteRecord;
        try {
            await this.signIn({ email: this.model.email, password: this.model.password });
            if ('afterSubmitRoute' in meta) {
                this.$router.push({ name: meta.afterSubmitRoute });
            }
        } catch (e) {
            this.$router.push({ name: 'Login' });
        }
    }

    private tagManager(): void {
        const script = document.createElement('script');
        const scripSklik = document.createElement('script');
        const scripSklikSrc = document.createElement('script');
        // eslint-disable-next-line prettier/prettier
        script.innerHTML = 'gtag(\'event\', \'conversion\', {\'send_to\': \'AW-861879920/6ej8CMqj1_wCEPD8_JoD\'});';
        const [first] = document.getElementsByTagName('head');
        first.appendChild(script);

        scripSklik.innerHTML = 'var seznam_cId = 100119268;var seznam_value = null;';
        first.appendChild(scripSklik);

        scripSklikSrc.setAttribute('src', 'https://www.seznam.cz/rs/static/rc.js');
        scripSklikSrc.setAttribute('async', '');

        first.appendChild(scripSklikSrc);
    }
}
