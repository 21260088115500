


























































































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class User extends Vue {
    get trans(): string {
        return this.$route.name === 'Registration' ? 'web.make_registration' : 'web.signin';
    }
}
