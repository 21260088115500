/**
 *  FRegistration model
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { Account } from '@interfaces/form.interface';

const Model: Account = {
    name: '',
    surname: '',
    company: '',
    email: '',
    phoneCode: '+420',
    phone: null,
};

export default Model;
