/**
 *  Form login model
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

export default {
    email: '',
    password: '',
};
