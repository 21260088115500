/**
 *  Orders interface
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 *
 */

import { ProductTemplate } from '@interfaces/products';

export enum OrderState {
    NEW = 1,
    ACTIVE,
    DEMO_EXPIRED,
    ACTIVE_PRODUCT,
}

export enum OrderType {
    TRIAL = '1',
    NOTTRIAL = '2',
}

export interface OrderMeta {
    readonly new: number;
    readonly inprogress: number;
    readonly accepted: number;
    readonly rejected: number;
}

export interface Order {
    readonly id: string;
    readonly state: OrderState;
    readonly type: string;
    readonly text: string;
    readonly oid: string;
    readonly createdAt: number;
    readonly updatedAt: number;
    readonly product: ProductTemplate;
}
