/* eslint-disable @typescript-eslint/naming-convention */
/**
 *  Component installer for globals components
 *  @author Algotech <info@algotech.cz>
 *  @namespace config
 *  @copyright 2021 Algotech a.s.
 */

export default {
    name: 'Algotech Portal',
    authTokemConst: 'AUTH_TOKEN',
    storage: {
        user: 'ALG_USER',
    },
    defaultCategory: 'owncloud',
    categoryIcon: {
        owncloud: 'cloud',
        virtualni_server: 'server',
    },
    categoryClass: {
        owncloud: {
            'alg-product-widget__head-icon--server': false,
            'alg-product-widget__params-item--server': false,
            'alg-product-widget-detail__icon--cloud': true,
            'alg-product-widget-detail__icon--server': false,
            'server-color': false,
        },
        virtualni_server: {
            'alg-product-widget__head-icon--server': true,
            'alg-product-widget__params-item--server': true,
            'alg-product-widget-detail__icon--cloud': false,
            'alg-product-widget-detail__icon--server': true,
            'server-color': true,
        },
    },
};
