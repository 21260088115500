



















import { Component, Vue, Model, Prop } from 'vue-property-decorator';

import InputTypeMixin from '@mixins/local/inputtype.mixin';

@Component
export default class AlgTextarea extends InputTypeMixin {
    @Model('change') readonly modelx!: any;
    @Prop({ type: String, default: 'text' }) readonly type!: string;
    @Prop({ type: Boolean, default: false }) readonly autosize!: boolean;
    @Prop({ type: Number, default: 4 }) readonly rows!: number;
}
