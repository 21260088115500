/**
 *  Product activation model
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { ProductActivation } from '@interfaces/form.interface';

const model: ProductActivation = {
    type: '2',
    confirm: false,
    text: '',
};

export default model;
