






import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { Mutation, Getter } from 'vuex-class';
import http from '@services/http.client';
import { AuthConst } from '@store/auth';

enum HeaderConfig {
    ONLY_MOBILE = 'only-mobile',
    ONLY_LOGO = 'only-logo',
}
@Component
export default class PageWrapper extends Vue {
    @Prop({ type: Boolean }) readonly isLoading!: boolean;
}
