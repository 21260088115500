import { RouteConfig } from 'vue-router';

import Login from '@pages/user/signin/Index.vue';
import Registration from '@pages/user/registration/Index.vue';
import SimpleRegistration from '@pages/user/registration/Simple.vue';
import ForgottenPassword from '@pages/user/forgotten-password/Index.vue';
import ResetPassword from '@pages/user/forgotten-password/Reset.vue';
import User from '@pages/user/User.vue';
import Account from '@pages/user/account/Index.vue';
import Header from '@components/page/Header.vue';
import Footer from '@/components/page/Footer.vue';
import Test from '@pages/user/Test.vue';

const route: RouteConfig[] = [
    {
        path: '/test',
        name: 'Test',
        component: Test,
        meta: {
            requiresAuth: false,
            header: ['only-mobile', 'only-logo'],
        },
    },
    {
        path: '/user/account',
        name: 'Account',
        components: {
            default: Account,
            header: Header,
            footer: Footer,
        },
        meta: {
            requiresAuth: true,
            header: [],
        },
    },
    {
        path: '/registration-simple',
        name: 'RegistrationSimple',
        component: SimpleRegistration,
        meta: {
            requiresAuth: false,
            afterSubmitRoute: 'Dashboard',
            header: ['only-mobile', 'only-logo'],
        },
    },
    {
        path: '/',
        components: {
            default: User,
            header: Header,
        },
        children: [
            {
                path: 'signin',
                name: 'Login',
                component: Login,
                meta: {
                    requiresAuth: false,
                    afterSubmitRoute: 'Dashboard',
                    header: ['only-mobile', 'only-logo'],
                },
            },
            {
                path: 'registration',
                name: 'Registration',
                component: Registration,
                meta: {
                    requiresAuth: false,
                    afterSubmitRoute: 'Dashboard',
                    header: ['only-mobile', 'only-logo'],
                },
            },
            {
                path: 'forgotten-password',
                name: 'FrogottenPassword',
                component: ForgottenPassword,
                meta: {
                    requiresAuth: false,
                    afterSubmitRoute: 'Login',
                    header: ['only-mobile', 'only-logo'],
                },
            },
            {
                path: 'forgotten-password/:uuid',
                name: 'ResetPassword',
                component: ResetPassword,
                props: true,
                meta: {
                    requiresAuth: false,
                    afterSubmitRoute: 'Login',
                    header: ['only-mobile', 'only-logo'],
                },
            },
            { path: '*', redirect: '/signin' },
        ],
    },
];

export default route;
