var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageWrapper',{attrs:{"isLoading":_vm.loading}},[_c('main',{staticClass:"alg-page"},[_c('div',{staticClass:"d-flex flex-column mt-5"},[_c('div',{staticClass:"alg-product-widget-detail-parent d-flex flex-wrap justify-content-center w-100"},[_c('div',{staticClass:"alg-product-widget-detail-info"},[_c('div',{staticClass:"mt-10"},[_c('div',{staticClass:"alg-h1 mb-4"},[_vm._v(_vm._s(_vm.$t('web.your_account')))]),_c('span',{staticClass:"alg-product-widget__head-text h1--big text-center mt-3",attrs:{"data-cy":"product-name"}},[_vm._v(" "+_vm._s(_vm.profile.profile.name)+" ")]),(!_vm.edit)?_c('div',{staticClass:"mt-3 user-account-break"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('web.company'))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.profile.company)+" ")])]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('web.phone'))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.profile.phoneCode)+" "+_vm._s(_vm.profile.phone)+" ")])]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t('web.email'))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.profile.profile.email)+" ")])])]):_vm._e(),[(!_vm.edit)?_c('div',[_c('el-button',{staticClass:"btn-simple-row",attrs:{"data-cy":"open-edit"},on:{"click":function($event){_vm.edit = true}}},[_vm._v(" "+_vm._s(_vm.$t('web.save_profile'))+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'long-arrow-alt-right']}})],1)],1):_vm._e()]],2),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{ref:"form",attrs:{"model":_vm.model,"label-width":"120px","label-position":"top"}},[_c('div',{staticClass:"alg-product-widget-detail__activation"},[(_vm.edit)?_c('section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"name","label":_vm.$t('web.name'),"errors":errors,"classes":classes,"required":required,"disabled":_vm.isSocial},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.surname'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"surname","label":_vm.$t('web.surname'),"errors":errors,"classes":classes,"required":required,"disabled":_vm.isSocial},model:{value:(_vm.model.surname),callback:function ($$v) {_vm.$set(_vm.model, "surname", $$v)},expression:"model.surname"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.company'),"rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"company","label":_vm.$t('web.company'),"errors":errors,"classes":classes,"required":required},model:{value:(_vm.model.company),callback:function ($$v) {_vm.$set(_vm.model, "company", $$v)},expression:"model.company"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col alg-select-prepended no-margin"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.phone'),"rules":"required|numeric|max:15|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgText',{attrs:{"prop":"phone","label":_vm.$t('web.phone'),"errors":errors,"classes":classes,"required":required,"type":"tel","autocomplete":"tel"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('AlgSelect',{attrs:{"prop":"phoneCode","isLabelCode":"","filterable":"","options":_vm.phoneCodes,"classes":{ phoneCode: true }},model:{value:(_vm.model.phoneCode),callback:function ($$v) {_vm.$set(_vm.model, "phoneCode", $$v)},expression:"model.phoneCode"}})]},proxy:true}],null,true),model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}],null,true)})],1)])]):_vm._e(),[(_vm.edit)?_c('div',{staticClass:"mt-2"},[_c('el-button',{staticClass:"btn-simple-row",attrs:{"data-cy":"save-edit","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('web.save'))+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'long-arrow-alt-right']}})],1)],1):_vm._e()]],2)])]}}])})],1),_c('div',{staticClass:"alg-product-widget-detail-promo alg-product-widget-detail-promo--back2"})]),_c('div',{staticClass:"d-flex justify-content-center w-100 mt-5 mb-5"},[_c('div',{staticClass:"d-none alg-product-widget-detail-info algo-info-box d-md-flex align-items-center justify-content-between"},[_c('p',{staticClass:"algo-info-box__text"},[_vm._v(" "+_vm._s(_vm.$t('web.empty_user_orders'))+" "),_c('br'),_c('span',{staticClass:"algo-info-box__text--sub"},[_vm._v(_vm._s(_vm.$t('web.free_demo')))])])]),_c('div',{staticClass:"alg-product-widget-detail-promo2 text-center"},[_c('router-link',{staticClass:"big-button-regular--same btn-fit btn-center",attrs:{"to":{ name: "Dashboard" }}},[_vm._v(" "+_vm._s(_vm.$t('web.product_proposal'))+" ")])],1)]),_c('Contact')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }