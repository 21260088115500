/**
 *  Orders schema
 *  @module schema
 *  @see https://graphql.org/learn/schema/
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import gql from 'graphql-tag';

import { productFragment } from './products';

export const metaOrders = gql`
    query metaOrders {
        metaOrders {
            new
            active
            demoExpired
            rejected
        }
    }
`;
export const orders = gql`
    query UserOrders($type: OrderState = ALL) {
        orders(type: $type) {
            id
            state
            type
            text
            oid
            createdAtDb
            updatedAtDb
            product {
                ...productFields
                category_name_normalize
                category_id
                description
                discount_price
            }
        }
    }

    ${productFragment}
`;
