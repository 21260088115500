





















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import { SmartQuery } from 'vue-apollo-decorators';
import { ProductTemplate } from '@interfaces/products';
import { ComponentOptions } from 'vue';
import { product, templates } from '@interfaces/gql/products';
import PageMixin from '@mixins/local/page.mixin';
import _ from 'lodash';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_product_detail',
        });
    },
};

@Component(def as ComponentOptions<Vue>)
export default class ProductDetail extends PageMixin {
    @Prop({ type: String }) public id!: string;

    @SmartQuery<ProductDetail>({
        query: product,
        variables() {
            return {
                pid: this.id,
            };
        },
        error(error) {
            this.gqlError('web.messages.product2_load_error', error);
        },
    })
    public product: ProductTemplate | null = null;

    @SmartQuery<ProductDetail>({
        query: templates,
        variables() {
            return {
                cid: this.product?.category_id,
            };
        },
        error(error) {
            if (!this.isError) {
                this.$message.error(this.$t('web.messages.product_load_error') as string);
                this.isError = true;
            } else {
                this.isError = false;
            }
        },
        update(data) {
            const newData = _.take(
                data.products.filter((d: ProductTemplate) => d.id !== this.id),
                4
            );

            return newData;
        },
        skip() {
            return !this.product?.category_id;
        },
    })
    public products: ProductTemplate[] | null = null;

    public goToConfirmation(): void {
        const params: Record<string, string> = { id: this.id };
        this.$router.push({ name: 'ProductConfirmation', params });
    }
}
