


















import { Component, Vue, Model, Prop } from 'vue-property-decorator';

import InputTypeMixin from '@mixins/local/inputtype.mixin';

@Component
export default class AlgText extends InputTypeMixin {
    @Model('change') readonly modelx!: any;
    @Prop({ type: String, default: 'text' }) readonly type!: string;
}
