/**
 *  Mixin base object
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */
import common from './common';

export default {
    mixins: [common],
};
