













































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SocialSignInComponent extends Vue {
    @Prop({ type: Boolean, default: false }) readonly simple!: boolean;
    public checked = false;
}
