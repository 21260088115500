/**
 *  Mat service
 *  @see https://vue-meta.nuxtjs.org/
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { i18n } from '@/services/i18n.service';
import { TranslateResult } from 'vue-i18n';
import CONFIG from '@config/app.config';

interface Meta {
    title?: TranslateResult;
    titleTemplate?: string;
}

const defaultTitle = 'web.defaultTitle';
const defaultTemplate = `%s | ${CONFIG.name}`;

export function meta(metaConfig: Meta = {}): Meta {
    if (!metaConfig?.title) {
        metaConfig.title = defaultTitle as TranslateResult;
    }
    if (!metaConfig?.titleTemplate) {
        metaConfig.titleTemplate = defaultTemplate;
    }

    return {
        title: i18n.t(metaConfig.title as string),
        titleTemplate: metaConfig.titleTemplate,
    };
}
