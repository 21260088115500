





























































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';

import { Getter } from 'vuex-class';
import { ComponentOptions } from 'vue';
import { SmartQuery } from 'vue-apollo-decorators';
import { categories as categoriesGQL, templates } from '@interfaces/gql/products';
import { ProductCategory, ProductTemplate } from '@interfaces/products';
import _ from 'lodash';
import $ from 'jquery';
import CONFIG from '@config/app.config';
import DIAL_CODES from '@config/dial-codes.config';
import gql from 'graphql-tag';
import PageMixin from '@mixins/local/page.mixin';
import { User } from '@interfaces/auth.interface';
import { EventBus } from '@services/event-bus';
import Contact from '@components/general/Contact.vue';

const AnimatedNumber = require('animated-number-vue');

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_dashboard',
        });
    },
    components: {
        AnimatedNumber,
        Contact,
    },
};

@Component(def as ComponentOptions<Vue>)
export default class Dashboard extends PageMixin {
    @Getter('auth/profile') profile!: User;

    @SmartQuery<Dashboard>({
        query: categoriesGQL,
        result({ data, loading, networkStatus }) {
            const first: ProductCategory = _.first(data.categories) as ProductCategory;
            this.selectedCategory = first.id;
        },
    })
    public categories: ProductCategory[] | null = null;

    @SmartQuery<Dashboard>({
        query: templates,
        variables() {
            return {};
        },
        error(error) {
            this.gqlError('web.messages.product_load_error', error);
        },
        skip() {
            return false;
        },
    })
    public products: ProductTemplate[] | null = null;

    private activeNameHidden: string = CONFIG.defaultCategory;

    private selectedCategory: string | null = null;

    public dialogFormVisible = false;

    public phoneLoading = false;

    public isContactSending = false;

    public number1 = 0;
    public number2 = 0;
    public number3 = 0;
    public number4 = 0;

    public model = {
        phoneCode: '+420',
        phone: null,
    };

    public phoneCodes: Record<'code' | 'name', string>[] = DIAL_CODES;

    mounted(): void {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1.0,
        };

        const observer = new IntersectionObserver((entries, observer) => {
            if (entries[0].isIntersecting) {
                this.number1 = 1300;
                this.number2 = 300;
                this.number3 = 41;
                this.number4 = 56;
                observer.unobserve(entries[0].target);
            }
        }, options);
        observer.observe(document.querySelector('#widget-info-number') as Element);

        if (this.$route?.query?.anchor) {
            setTimeout(() => {
                this.goTo(this.$route.query.anchor as string);
            }, 100);
        }
    }

    handleClick(e: Record<string, string>): void {
        // el-tabs__active-bar
        if (e.paneName === 'cloud') {
            (document.querySelector('.el-tabs__active-bar') as HTMLElement).style.backgroundColor = '#1dabe4';
        }
        if (e.paneName === 'server') {
            (document.querySelector('.el-tabs__active-bar') as HTMLElement).style.backgroundColor = '#616eb4';
        }
    }

    public goTo(anchor: string): void {
        $('html, body').animate(
            {
                scrollTop: $(`#${anchor}`).offset()?.top,
            },
            500
        );
    }

    public productSelect(value: ProductTemplate[], category: string): ProductTemplate[] {
        if (!value) {
            return [];
        }
        return value.filter((v) => v.category_id === category);
    }

    public theFormat(n: number): string {
        return n.toFixed(0);
    }

    public async onContact(): Promise<void> {
        try {
            this.isContactSending = true;
            await this.checkPhone();
            await this.createEmptyLead();
        } catch (e) {
            this.$message.error(
                this.$t('web.form.server_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string
            );
        } finally {
            this.isContactSending = false;
        }
    }

    private checkPhone(): Promise<true> {
        return new Promise((resolve) => {
            EventBus.$on('onEmptyLead', () => {
                resolve(true);
            });

            if (this.profile.phone && this.profile.phone.length > 0) {
                resolve(true);
            } else {
                this.dialogFormVisible = true;
            }
        });
    }

    public async savePhone(): Promise<void> {
        try {
            this.phoneLoading = true;
            const { data } = await this.$apollo.mutate({
                // Query
                mutation: gql`
                    mutation ProfilePhone($input: ProfilePhoneInput) {
                        profilePhone(identity: $input) {
                            status
                            message
                        }
                    }
                `,
                // Parameters
                variables: {
                    input: this.model,
                },
            });
            this.dialogFormVisible = false;
            EventBus.$emit('onEmptyLead');
        } catch (e) {
            this.$message.error(
                this.$t('web.form.server_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string
            );
        } finally {
            this.phoneLoading = false;
        }
    }

    get activeName(): string {
        const first = _.head(this.categories) as ProductCategory;
        return first?.name_normalize ?? CONFIG.defaultCategory;
    }

    set activeName(name: string) {
        const find = this.categories?.find((c) => c.name_normalize === name);
        if (find) {
            this.selectedCategory = find.id;
        }
        this.activeNameHidden = name;
    }

    get topClass(): Record<string, boolean> {
        return { [this.activeNameHidden]: true };
    }
}
