import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { RootState } from '@interfaces/store.interface';
import { Type, User } from '@interfaces/auth.interface';
import config from '@config/app.config';
import { storage } from '@services/storage.service';

export enum ProductConst {
    TOGGLE_ORDER_DONE = 'TOGGLE_ORDER_DONE',
}

interface ProductState {
    orderDone: boolean;
}

const stateOb: ProductState = {
    orderDone: false,
};

const gettersOb: GetterTree<ProductState, RootState> = {
    isOrderDone(state: ProductState) {
        return state.orderDone;
    },
};

const mutationsOb: MutationTree<ProductState> = {
    [ProductConst.TOGGLE_ORDER_DONE](state, payload: boolean) {
        state.orderDone = payload;
    },
};

const actions: ActionTree<ProductState, RootState> = {
    setOrderDone({ commit }, payload: boolean) {
        commit(ProductConst.TOGGLE_ORDER_DONE, payload);
        if (payload) {
            setTimeout(() => {
                commit(ProductConst.TOGGLE_ORDER_DONE, false);
            }, 10000);
        }
    },
};

const namespaced = true;

const module: Module<ProductState, RootState> = {
    namespaced,
    state: stateOb,
    getters: gettersOb,
    mutations: mutationsOb,
    actions,
};

export default module;
