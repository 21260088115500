/**
 *  Component installer for globals components
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Promised } from 'vue-promised';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

import LocaleChangerComponent from '@components/general/LangChangerComponent.vue';
import PageWrapper from '@components/page/PageWrapper.vue';
import AlgBasicType from '@components/forms/inputtypes/AlgBasicType.vue';
import AlgText from '@components/forms/inputtypes/AlgText.vue';
import AlgTextarea from '@components/forms/inputtypes/AlgTextarea.vue';
import AlgSelect from '@components/forms/inputtypes/AlgSelect.vue';
import Product from '@components/products/Product.vue';
import AlgInputContainer from '@components/forms/inputtypes/AlgInputContainer.vue';
import { number } from '@components/filters/Number';

export default class {
    public static install(vue: typeof Vue, options?: unknown): void {
        vue.component('font-awesome-icon', FontAwesomeIcon);
        vue.component('Promised', Promised);
        vue.component('app-lang', LocaleChangerComponent);
        vue.component('PageWrapper', PageWrapper);
        vue.component('ValidationProvider', ValidationProvider);
        vue.component('ValidationObserver', ValidationObserver);

        // Form types
        vue.component('AlgInput', AlgBasicType);
        vue.component('AlgText', AlgText);
        vue.component('AlgSelect', AlgSelect);
        vue.component('AlgTextarea', AlgTextarea);

        vue.component('AlgInputContainer', AlgInputContainer);

        // Products
        vue.component('Product', Product);

        // Filters
        vue.filter('number', number);
    }
}
