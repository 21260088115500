









































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import _ from 'lodash';
import { Mutation, Getter } from 'vuex-class';
import http from '@services/http.client';
import { AuthConst } from '@store/auth';
import { SmartQuery } from 'vue-apollo-decorators';
import { metaOrders } from '@interfaces/gql/orders';
import { OrderMeta } from '@interfaces/orders';

@Component
export default class Footer extends Vue {
    @Getter('auth/isAuth') isAuth!: boolean;
    @Ref('footer') footer!: HTMLElement;

    private breakPoint: number | null = null;
    private maxHeight = 220;

    mounted() {
        // TODO: does not work right
        // const method = () => {
        //     const currentY = window.pageYOffset;
        //     if (this.breakPoint) {
        //         const height = this.footer.offsetHeight;
        //         const diff = Math.max(100 + currentY - this.breakPoint, 100);
        //         if (diff <= this.maxHeight) {
        //             this.footer.style.height = `${diff}px`;
        //         } else {
        //             this.footer.style.height = `${this.maxHeight}px`;
        //         }
        //     }
        // };
        // const method2 = () => {
        //     this.footer.style.height = `${this.maxHeight}px`;
        //     this.breakPoint = null;
        // };
        // window.addEventListener('scroll', method);
        // window.addEventListener('resize', method2);
        // const intersectionObserver = new IntersectionObserver((entries) => {
        //     if (entries[0].intersectionRatio <= 0) {
        //         this.footer.style.height = '100px';
        //         this.breakPoint = null;
        //         return;
        //     }
        //     this.breakPoint = window.pageYOffset;
        //     if (this.breakPoint === 0) {
        //         this.footer.style.height = `${this.maxHeight}px`;
        //     }
        // });
        // intersectionObserver.observe(this.footer);
    }
}
