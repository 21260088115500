/**
 *  Declaration of all translations per lang
 *  Declaration of forms validaiton messages
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { localize } from 'vee-validate';

import formsCs from 'vee-validate/dist/locale/cs.json';
import formsEn from 'vee-validate/dist/locale/en.json';

import localeCs from '@assets/i18n/locale.cs.json';
import localeEn from '@assets/i18n/locale.en.json';

import { MAIN_LANG } from '@config/i18n.config';
import { Locale } from '@interfaces/i18n.interface';

const messages = {
    [Locale.En]: localeEn,
    [Locale.Cs]: localeCs,
};

const formsMessages = {
    [Locale.En]: {
        messages: { ...formsEn.messages, ...localeEn.veevalidate.messages },
    },
    [Locale.Cs]: {
        messages: { ...formsCs.messages, ...localeCs.veevalidate.messages },
    },
};

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: MAIN_LANG,
    messages,
    fallbackLocale: [Locale.Cs],
});

const initFormsLocalize = (): void => {
    localize(formsMessages);
    localize(MAIN_LANG);
};

export { formsMessages, i18n, messages, initFormsLocalize };
