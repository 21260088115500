/**
 *  Basic setup form mixin
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { Ref } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';
import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';
import gql from 'graphql-tag';
import { ExtRecord } from '@interfaces/global.interface';
import { MutationOptions } from 'apollo-client';
import { RouteRecord } from 'vue-router';
import { Mutation } from 'vuex-class';
import { AuthConst } from '@store/auth';
import { User } from '@interfaces/auth.interface';
import http from '@services/http.client';

@Component
export default class FormMixin extends Vue {
    @Ref('form') readonly formObserver!: InstanceType<typeof ValidationObserver>;
    @Mutation(`auth/${AuthConst.TOGGLE_NOTAUTH}`) readonly mutationNotAuth!: (payload: boolean) => void;
    @Mutation(`auth/${AuthConst.AUTH_SUCCESS}`) readonly mutationAuthSuccess!: (payload: User) => void;

    public model: any = null;

    protected mutation = '';

    protected customSuccessMessage: string | null = null;

    protected isFormLoading = false;

    /**
     * If true confirm dialog will not be fired
     */
    protected customNotification = false;

    protected init<T>(model: T): void {
        this.model = _.cloneDeep(model);
    }

    public get loading(): boolean {
        return this.$apollo?.loading || this.isFormLoading;
    }

    public onSubmit(): Promise<unknown> {
        return this.formObserver.validate().then(async (success) => {
            if (!success) {
                this.$message.error(this.$t('web.form.validate_error') as string);
                return;
            }

            const model = _.cloneDeep(this.model);

            try {
                this.beforeSubmitHook(model);
                this.isFormLoading = true;
                const { data } = await this.$apollo.mutate(this.defMutation(model));

                const { status = false, message = '' } = (data as any)[this.mutation];

                if (!status) {
                    this.$message.error(
                        this.$t(message, {
                            support_email: process.env.VUE_APP_SUPPORT_EMAIL,
                        }) as string
                    );
                    return;
                }

                const successMessage: string = (this.customSuccessMessage
                    ? this.$t(this.customSuccessMessage)
                    : this.$t('web.form.submit_success')) as string;

                if (!this.customNotification) {
                    this.$message({
                        message: successMessage,
                        type: 'success',
                        duration: 10000,
                    });
                }
                this.afterSubmitHook(data);
                this.formObserver.reset();
            } catch (e) {
                this.$message.error(
                    this.$t('web.form.server_error', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string
                );
            } finally {
                this.isFormLoading = false;
            }
        });
    }

    protected async signIn(model: ExtRecord<string | number | boolean>): Promise<void> {
        this.mutationNotAuth(false);
        this.start();
        const resp = await http.post<User>(
            '/api/user/login',
            {
                email: model.email,
                password: model.password,
            },
            { withCredentials: true }
        );
        this.mutationAuthSuccess(resp);
    }

    /**
     * Define params for mutation
     * @see https://apollo.vuejs.org/guide/apollo/mutations.html#server-side-example
     */
    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return { mutation: gql`` };
    }

    /**
     * Call before submit
     */
    protected beforeSubmitHook(model: ExtRecord<string | number>): void {}

    /**
     * Call after submit
     */
    protected afterSubmitHook(result: ExtRecord<string | number> | null | undefined): void {
        const { meta } = _.last(this.$route.matched) as RouteRecord;

        if ('afterSubmitRoute' in meta) {
            this.$router.push({ name: meta.afterSubmitRoute });
        }
    }

    async recaptcha() {
        await this.$recaptchaLoaded();
        return this.$recaptcha('login');
    }

    protected start(): void {
        this.isFormLoading = true;
    }

    protected finish(): void {
        this.isFormLoading = false;
    }
}
