var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"login-page"},[(_vm.isNotAuth)?_c('el-alert',{attrs:{"title":_vm.$t('web.not_auth_alert'),"data-cy":"notAuth-alert","show-icon":"","type":"warning","effect":"dark","role":"alert"}}):_vm._e(),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('el-form',{ref:"form",attrs:{"model":_vm.model,"label-width":"120px","label-position":"top"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col alg-h1 mb-4"},[_vm._v(" "+_vm._s(_vm.$t('web.forgotten_password_reset'))+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-mid"},[_vm._v(" "+_vm._s(_vm.$t('web.forgotten_password_text'))+" ")])]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col no-margin"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.password'),"rules":"required|algPassword|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"password","label":_vm.$t('web.new_password'),"errors":errors,"classes":classes,"password":true,"required":required},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col no-margin"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('web.passwordConfirm'),"rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var classes = ref.classes;
return [_c('AlgInput',{attrs:{"prop":"confirmation","label":_vm.$t('web.passwordConfirm'),"errors":errors,"classes":classes,"password":true,"required":required},model:{value:(_vm.confirmation),callback:function ($$v) {_vm.confirmation=$$v},expression:"confirmation"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('el-button',{staticClass:"big-button",attrs:{"type":"primary","disabled":invalid,"data-cy":"signin-submit"},on:{"click":function($event){$event.preventDefault();return _vm.onLocalSubmit($event)}}},[_vm._v(" "+_vm._s(_vm.$t('web.forgotten_password_reset_buttton'))+" ")])],1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-left link-small"},[_vm._v(" "+_vm._s(_vm.$t('web.yes_account'))+" "),_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v(_vm._s(_vm.$t('web.make_signin')))])],1)]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col text-center"},[_c('app-lang')],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }