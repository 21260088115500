







































































































































































// eslint-disable-next-line max-classes-per-file
import { Component, Vue, Prop, Mixins } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import { meta } from '@/services/meta.service';
import { SmartQuery } from 'vue-apollo-decorators';
import { ProductActivation } from '@interfaces/form.interface';
import { ProductTemplate } from '@interfaces/products';
import MODEL from '@services/models/product-activation.model';
import { product } from '@interfaces/gql/products';
import { orders } from '@interfaces/gql/orders';
import { ComponentOptions } from 'vue';
import PageMixin from '@mixins/local/page.mixin';
import FormMixin from '@mixins/local/form.mixin';
import { User } from '@interfaces/auth.interface';
import RelatedProduct from '@components/products/RelatedProducts.vue';
import { Order, OrderState } from '@interfaces/orders';

import { ExtRecord } from '@interfaces/global.interface';
import { MutationOptions } from 'apollo-client';
import gql from 'graphql-tag';
import _ from 'lodash';
import { visitWithTypeInfo } from 'graphql';

const def = {
    metaInfo() {
        return meta({
            title: 'web.title_product_confirmation',
        });
    },
    components: {
        RelatedProduct,
    },
};

@Component(def as ComponentOptions<Vue>)
export default class ProductConfirmation extends Mixins(PageMixin, FormMixin) {
    @Prop({ type: String }) public id!: string;

    @Getter('auth/name') name!: string;
    @Getter('auth/email') email!: string;
    @Getter('auth/isLocal') isLocalAuth!: boolean;
    @Getter('auth/profile') profileGetter!: User | null;

    @Action('product/setOrderDone') readonly actionOrder!: (payload: boolean) => void;

    public dialogTableVisible = false;

    protected mutation = 'createOrder';

    protected customSuccessMessage: string | null = 'web.form.submit_success_order';

    @SmartQuery<ProductConfirmation>({
        query: orders,
        fetchPolicy: 'no-cache',
        loadingKey: 'oid',
        variables() {
            return {};
        },
        error(error) {
            this.gqlError('web.messages.orders_load_error', error);
        },
    })
    public orders: Order[] | null = null;

    @SmartQuery<ProductConfirmation>({
        query: product,
        variables() {
            return {
                pid: this.id,
            };
        },
        error(error) {
            this.gqlError('web.messages.product2_load_error', error);
        },
    })
    public product: ProductTemplate | null = null;

    created(): void {
        this.init<ProductActivation>(MODEL);
        this.customNotification = true;
    }

    public onSubmitLocal(): Promise<unknown> {
        this.isFormLoading = true;

        if (this.orders && this.isSameProductOrdered()) {
            this.$alert(
                this.$t('web.order_duplicate', { support_email: process.env.VUE_APP_SUPPORT_EMAIL }) as string,
                this.$t('web.attentione') as string,
                {
                    confirmButtonText: 'OK',
                    type: 'warning',
                    callback: (action) => {
                        this.$router.push({ name: 'Dashboard', query: { anchor: 'contact2' } });
                    },
                }
            );

            this.isFormLoading = false;
            return Promise.reject();
        }

        return this.onSubmit();
    }

    protected isSameProductOrdered(): boolean {
        return this.orders!.some((order: Order): boolean => {
            if (this.product!.category_name_normalize === 'owncloud') {
                return order.product.category_name_normalize === this.product!.category_name_normalize;
            }

            let virtServerType = null;

            if (this.product?.name_normalize.includes('linux_server')) {
                virtServerType = 'linux_server';
            } else if (this.product?.name_normalize.includes('windows_server')) {
                virtServerType = 'windows_server';
            }

            if (virtServerType === null) {
                return false;
            }

            return order.product.name_normalize.startsWith(virtServerType!);
        });
    }

    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return {
            // Query
            mutation: gql`
                mutation CreateOrder($input: OrderInput) {
                    createOrder(data: $input) {
                        status
                        message
                    }
                }
            `,
            // Parameters
            variables: {
                input: model,
            },
        };
    }

    protected beforeSubmitHook(model: ExtRecord<string | number>): void {
        model.id = this.id;
    }

    /**
     * Call after submit
     */
    protected afterSubmitHook(result: ExtRecord<string | number> | null | undefined): void {
        this.actionOrder(true);
        this.init<ProductActivation>(MODEL);
        this.formObserver.reset();
        this.$router.push({ name: 'ProductActivation' });
        // this.dialogTableVisible = true;
        // setTimeout(() => {
        //     this.dialogTableVisible = false;
        // }, 8000);
    }
}
