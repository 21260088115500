/**
 *  Input type mixin
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { ModelTypes } from '@interfaces/form.interface';

import _ from 'lodash';

@Component
export default class InputTypeMixin extends Vue {
    public modelx: any;

    @Prop({ default: '' }) readonly prop!: string;

    @Prop({ default: '' }) readonly label!: string;

    @Prop({ default: '' }) readonly placeholder!: string;

    @Prop({ type: Boolean, default: false }) readonly password!: boolean;

    @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

    @Prop({ type: Boolean, default: false }) readonly filterable!: boolean;

    @Prop({ type: Boolean, default: false }) readonly isLabelCode!: boolean;

    @Prop({ type: String, default: 'off' }) readonly autocomplete!: string;

    @Prop({ type: Array }) readonly errors!: string[];

    @Prop({ type: Object }) readonly classes!: typeof Object;

    @Prop({ type: Array }) readonly options!: Record<string, string | number>[];

    @Prop({ type: Boolean, default: false }) readonly required!: boolean;

    created() {
        if (_.isUndefined(this.modelx)) {
            throw new Error(`Form input type not defined or false: ${this.prop}`);
        }
    }

    get localModel(): ModelTypes {
        return this.modelx;
    }

    set localModel(val: ModelTypes) {
        this.emitChange(val);
    }

    @Emit('change')
    emitChange<T>(val: T): T {
        return val;
    }
}
