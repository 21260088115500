



































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import { Mutation, Getter } from 'vuex-class';
import http from '@services/http.client';
import { AuthConst } from '@store/auth';
import { SmartQuery } from 'vue-apollo-decorators';
import { metaOrders } from '@interfaces/gql/orders';
import { OrderMeta } from '@interfaces/orders';

enum HeaderConfig {
    ONLY_MOBILE = 'only-mobile',
    ONLY_LOGO = 'only-logo',
}
@Component
export default class Header extends Vue {
    @Mutation(`auth/${AuthConst.SIGN_OUT}`) readonly mutationSignOut!: () => void;
    @Getter('product/isOrderDone') isOrderDone!: boolean;
    @Getter('auth/isAuth') isAuth!: boolean;

    private headerConfig: string[] = [];

    @Watch('$route', { immediate: true })
    onRouteChange(): void {
        const { header = null } = _.last(this.$route?.matched)?.meta;
        this.headerConfig = header;
    }

    @SmartQuery<Header>({
        query: metaOrders,
        pollInterval: 10000,
        skip() {
            return !this.isAuth;
        },
    })
    public metaOrders: OrderMeta | null = null;

    public scrollPosition = 0;

    private toggleNav = false;

    created() {
        document.addEventListener('scroll', (e) => {
            const lastKnownScrollPosition = window.scrollY;

            this.$nextTick(() => {
                this.scrollPosition = lastKnownScrollPosition;
            });
        });
    }

    /**
     * User signed out
     */
    public async signout(): Promise<void> {
        try {
            await http.get<null>('/api/user/logout', { withCredentials: true });
            this.mutationSignOut();
            this.$router.push({ name: 'Login' });
            this.$message(this.$t('web.user_logout') as string);
        } catch (e) {
            this.$message.error(this.$t('web.form.server_error') as string);
        }
    }

    public toggleMobNav(): void {
        this.toggleNav = !this.toggleNav;
    }

    get isScroll(): boolean {
        return this.scrollPosition > 0;
    }

    get newOrdersNum(): number | null {
        return this.metaOrders?.new ?? null;
    }

    get headerClass(): Record<string, boolean> {
        let ob: Record<string, boolean> = { toggleNav: this.toggleNav };

        if (this.headerConfig?.includes(HeaderConfig.ONLY_MOBILE)) {
            ob = { 'd-lg-none': true, ...ob };
        }
        if (this.headerConfig?.includes(HeaderConfig.ONLY_LOGO)) {
            ob = { 'navbar--hide-nav': true, ...ob };
        }
        return ob;
    }
}
