/**
 *  i18n interface
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 *
 */

// eslint-disable-next-line no-shadow
export enum Locale {
    Cs = 'cs',
    En = 'en',
}
