import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import modulesInit from '@modules/modules.init';
import { state } from '@store/general';
import { RootState } from '@interfaces/store.interface';

import auth from '@store/auth';
import product from '@store/product';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
    modules: {
        auth,
        product,
        ...modulesInit.store,
    } as any,
    state,
};

export default new Vuex.Store<RootState>(store);
