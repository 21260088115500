










import { Component, Model, Prop } from 'vue-property-decorator';
import InputTypeMixin from '@mixins/local/inputtype.mixin';

@Component
export default class AlgBasicType extends InputTypeMixin {
    @Model('change') readonly modelx!: any;
    @Prop({ type: String, default: 'text' }) readonly inputType!: string;

    get component(): string {
        let type = 'AlgText';
        switch (this.inputType) {
            case 'textarea':
                type = 'AlgTextarea';
                break;
            default:
                type = 'AlgText';
        }

        return type;
    }
}
