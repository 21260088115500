/**
 *  General pages routing
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import NotFoundPage from '@pages/general/404.vue';
import Contact from '@pages/general/Contact.vue';
import { RouteConfig } from 'vue-router';
import Header from '@components/page/Header.vue';
import Footer from '@/components/page/Footer.vue';

const route: RouteConfig[] = [
    {
        // will match everything else 404 Page
        path: '/contact',
        name: 'Contact',
        components: {
            default: Contact,
            header: Header,
            footer: Footer,
        },
        meta: {
            requiresAuth: false,
            header: [],
        },
    },
    {
        // will match everything else 404 Page
        path: '*',
        name: '404',
        component: NotFoundPage,
        meta: {
            code: 'default',
        },
    },
];

export default route;
