/* eslint-disable no-console */
/**
 *  Http client instance
 *  @author Livescore <score-stats.com>
 *  @copyright 2021 Algotech
 */

import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
import APP_CONFIG from '@config/app.config';
import { ExtRecord } from '@/interfaces/global.interface';

export class HttpClient {
    private instance!: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            baseURL: process.env.VUE_APP_SERVER_URL,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            withCredentials: false,
            responseType: 'json',
        });
    }

    public async get<T>(url: string, options?: Record<string, any>, onlyData = true): Promise<T> {
        try {
            const resp: AxiosResponse = await this.instance.get(url, options);
            return onlyData ? resp.data : resp;
        } catch (e) {
            this.handleError(e);
            return Promise.reject(this.errorResponse(e));
        }
    }

    public async post<T>(
        url: string,
        data: Record<string, unknown>,
        options?: ExtRecord<string | number | boolean>,
        onlyData = true
    ): Promise<T> {
        try {
            const resp: AxiosResponse = await this.instance.post(url, data, options);
            return onlyData ? resp.data : resp;
        } catch (e) {
            this.handleError(e);
            return Promise.reject(this.errorResponse(e));
        }
    }

    private errorResponse(error: AxiosError) {
        return {
            data: error.response?.data,
            status: error.response?.status,
            headers: error.response?.headers,
            config: error.config,
        };
    }

    private handleError(error: AxiosError): void {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    }
}

const http = new HttpClient();
export default http;
