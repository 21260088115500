























import { Component, Vue } from 'vue-property-decorator';
import { AuthConst } from '@store/auth';
import _ from 'lodash';
import http from '@services/http.client';
import { RouteRecord } from 'vue-router';
import { Getter } from 'vuex-class';
import { User, Type } from '@interfaces/auth.interface';

import CookieConsent from 'vue-cookieconsent-component';

import JSON from '../package.json';

@Component({
    components: {
        CookieConsent,
    },
})
export default class App extends Vue {
    @Getter('auth/profile') profileGetter: User | null | undefined;
    @Getter('auth/isAuth') isAuth!: boolean;

    get profileInfo(): string | undefined {
        if (this.profileGetter?.type === Type.LOCAL) {
            return `${this.profileGetter?.name} ${this.profileGetter?.surname}`;
        }
        if (this.profileGetter?.type === Type.FACEBOOK) {
            return `${this.profileGetter?.displayName}`;
        }
        if (this.profileGetter?.type === Type.GOOGLE) {
            return `${this.profileGetter?.displayName}`;
        }

        return 'Not Auth';
    }

    get version(): string {
        return JSON.version;
    }
}
