import { Module, GetterTree, MutationTree, ActionTree } from 'vuex';
import { RootState } from '@interfaces/store.interface';
import { Type, User } from '@interfaces/auth.interface';
import config from '@config/app.config';
import { storage } from '@services/storage.service';
import { EventBus } from '@services/event-bus';
import http from '@services/http.client';

const user: User | null = storage.get(config.storage.user);

interface AuthState {
    user: User | null;
    isAuth: boolean;
    notAuth: boolean;
}

export enum AuthConst {
    TOGGLE_AUTH = 'TOGGLE_AUTH',
    TOGGLE_NOTAUTH = 'TOGGLE_NOTAUTH',
    AUTH_SUCCESS = 'AUTH_SUCCESS',
    SIGN_OUT = 'SIGN_OUT',
}

const stateOb: AuthState = {
    user: user || null,
    isAuth: !!user,
    notAuth: false,
};

const gettersOb: GetterTree<AuthState, RootState> = {
    isAuth(state: AuthState) {
        return state.isAuth;
    },
    isNotAuth(state: AuthState) {
        return state.notAuth;
    },
    isLocal(state: AuthState): boolean {
        return Type.LOCAL === state.user?.type;
    },
    isSocial(state: AuthState): boolean {
        return Type.FACEBOOK === state.user?.type || Type.GOOGLE === state.user?.type;
    },
    profile(state: AuthState): User | null {
        return state.user;
    },
    name(state: AuthState): string {
        if (state.user?.profile?.name) {
            return state.user?.profile?.name;
        }

        if (state.user?.name && state.user?.surname) {
            return `${state.user?.name} ${state.user?.surname}`;
        }

        if (Type.FACEBOOK === state.user?.type || Type.GOOGLE === state.user?.type) {
            return `${state.user?.displayName}`;
        }
        return `${state.user?.name} ${state.user?.surname}`;
    },
    firstName(state: AuthState): string {
        if (Type.FACEBOOK === state.user?.type || Type.GOOGLE === state.user?.type) {
            return `${(state.user?.name as Record<string, string>)?.givenName ?? ''}`;
        }
        return `${state.user?.name}`;
    },
    surName(state: AuthState): string {
        if (Type.FACEBOOK === state.user?.type || Type.GOOGLE === state.user?.type) {
            return `${(state.user?.name as Record<string, string>)?.familyName ?? ''}`;
        }
        return `${state.user?.surname}`;
    },
    email(state: AuthState): string {
        if (Type.FACEBOOK === state.user?.type || Type.GOOGLE === state.user?.type) {
            return `${state.user?.emails[0].value}`;
        }
        return `${state.user?.email}`;
    },
};

const mutationsOb: MutationTree<AuthState> = {
    [AuthConst.TOGGLE_AUTH](state, payload: boolean): void {
        state.isAuth = payload;
    },
    [AuthConst.TOGGLE_NOTAUTH](state, payload: boolean): void {
        state.notAuth = payload;
    },
    [AuthConst.AUTH_SUCCESS](state, payload: User): void {
        EventBus.$emit('statusLoaded', true);
        state.user = payload;
        state.isAuth = true;
        storage.set<User>(config.storage.user, payload);
    },
    [AuthConst.SIGN_OUT](state): void {
        state.user = null;
        state.isAuth = false;
        storage.remove(config.storage.user);
    },
};

const actions: ActionTree<AuthState, RootState> = {
    status({ commit }): Promise<User> {
        return http.get<User>('/api/user/status', { withCredentials: true });
    },
};

const namespaced = true;

const module: Module<AuthState, RootState> = {
    namespaced,
    state: stateOb,
    getters: gettersOb,
    mutations: mutationsOb,
    actions,
};

export default module;
