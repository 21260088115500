import Vue from 'vue';
import Meta from 'vue-meta';
import VueApollo from 'vue-apollo';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faBars,
    faBraille,
    faCommentAlt,
    faUser,
    faChevronRight,
    faCloud,
    faServer,
    faSortDown,
    faExclamationTriangle,
    faDiceD6,
    faInfoCircle,
    faHourglassStart,
    faPlayCircle,
    faEnvelope,
    faStopwatch,
    faTimes,
    faDotCircle,
    faCheckCircle,
    faExclamationCircle,
    faExternalLinkAlt,
    faHandHoldingUsd,
    faDollarSign,
    faHistory,
    faBox,
    faMeh,
    faPhone,
    faInfo,
    faCog,
    faMoneyBillWave,
    faMoneyBill,
    faHandshake,
    faLongArrowAltRight,
} from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faFacebook, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import Element from 'element-ui';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import { extend, configure } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import '@services/validate/validate.rules';

import LocalComponentsInstaller from '@components/local.components.installer';
import Mixins from '@mixins/global';

import { i18n, initFormsLocalize } from '@/services/i18n.service';

import { apolloClient } from '@services/apollo/client';

import App from './App.vue';
import router from './router';
import store from './store';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/scss/_mixins.scss';
import 'element-ui/lib/theme-chalk/index.css';
import '@/sass/main.scss';
// eslint-disable-next-line import/extensions
import 'bootstrap/dist/js/bootstrap.min.js';

library.add(faBars);
library.add(faPhone);
library.add(faUser);
library.add(faBraille);
library.add(faHistory);
library.add(faCommentAlt);
library.add(faGoogle);
library.add(faLinkedinIn);
library.add(faHandshake);
library.add(faFacebook);
library.add(faCloud);
library.add(faCog);
library.add(faTimes);
library.add(faMoneyBill);
library.add(faLongArrowAltRight);
library.add(faMoneyBillWave);
library.add(faBox);
library.add(faInfo);
library.add(faMeh);
library.add(faDollarSign);
library.add(faServer);
library.add(faExclamationCircle);
library.add(faChevronRight);
library.add(faSortDown);
library.add(faDiceD6);
library.add(faStopwatch);
library.add(faEnvelope);
library.add(faExternalLinkAlt);
library.add(faInfoCircle);
library.add(faCheckCircle);
library.add(faPlayCircle);
library.add(faHourglassStart);
library.add(faDotCircle);
library.add(faHandHoldingUsd);
library.add(faExclamationTriangle);

// Vee-validate state classes configuration
configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        pristine: 'is-pristine',
        required: 'is-required',
        dirty: ['is-dirty'],
    },
});

// Add all Vee-validate rules
// eslint-disable-next-line no-restricted-syntax
for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
    });
}

// Apollo provider
const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

Vue.config.productionTip = false;

const locale = require('element-ui/lib/locale/lang/cs-CZ');

Vue.use(LocalComponentsInstaller);

Vue.use(Element, { locale });
Vue.use(Meta);
Vue.use(VueApollo);
Vue.use(VueReCaptcha, { siteKey: '6LdDLBgbAAAAAFUfPyBEKRw5uDc1Wb5nip9YJJl-' });

Vue.mixin(Mixins);

initFormsLocalize();

new Vue({
    router,
    store,
    i18n,
    apolloProvider,
    render: (h) => h(App),
}).$mount('#app');
