












// eslint-disable-next-line max-classes-per-file
import { Component, Prop } from 'vue-property-decorator';
import { SmartQuery } from 'vue-apollo-decorators';
import { ProductTemplate } from '@interfaces/products';
import { templates } from '@interfaces/gql/products';
import PageMixin from '@mixins/local/page.mixin';
import _ from 'lodash';

@Component
export default class RelatedProduct extends PageMixin {
    @Prop({ type: String }) public id!: string;
    @Prop({ type: String }) public categoryId!: string;
    @Prop({ type: String }) public normalizeName!: string;

    @SmartQuery<RelatedProduct>({
        query: templates,
        variables() {
            return {
                cid: this.categoryId,
            };
        },
        error(error) {
            if (!this.isError) {
                this.$message.error(this.$t('web.messages.product_load_error') as string);
                this.isError = true;
            } else {
                this.isError = false;
            }
        },
        update(data) {
            const newData = _.take(
                data.products.filter((d: ProductTemplate) => d.id !== this.id),
                4
            );

            return newData;
        },
        skip() {
            return !this.categoryId;
        },
    })
    public products: ProductTemplate[] | null = null;
}
