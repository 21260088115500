/**
 *  Page routing
 *
 *  Define all page routes here !!!
 *
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

import { RouteConfig } from 'vue-router';

import General from '@pages/general/router';
import User from '@/pages/user/router';
import Products from '@/pages/products/router';

const routes: RouteConfig[] = [...Products, ...User, ...General];

export default routes;
