










































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { meta } from '@/services/meta.service';
import MODEL from '@services/models/account.model';
import { Getter, Action, Mutation } from 'vuex-class';
import { User } from '@interfaces/auth.interface';
import { ComponentOptions } from 'vue';
import FormMixin from '@mixins/local/form.mixin';
import { ExtRecord } from '@interfaces/global.interface';
import gql from 'graphql-tag';
import { MutationOptions } from 'apollo-client';
import DIAL_CODES from '@config/dial-codes.config';
import { AuthConst } from '@store/auth';
import Contact from '@components/general/Contact.vue';

const def = {
    metaInfo() {
        return meta({
            title: 'web.user_account',
        });
    },
    components: {
        Contact,
    },
};

@Component(def as ComponentOptions<Vue>)
export default class Account extends FormMixin {
    @Getter('auth/isSocial') isSocial!: boolean;
    @Getter('auth/firstName') firstName!: string;
    @Getter('auth/surName') surName!: string;
    @Getter('auth/profile') profile!: User | null;
    @Action('auth/status') actionStatus!: () => Promise<User>;
    @Mutation(`auth/${AuthConst.AUTH_SUCCESS}`) readonly mutationAuth!: (payload: User) => void;

    public phoneCodes: Record<'code' | 'name', string>[] = DIAL_CODES;

    public edit = false;

    protected mutation = 'profile';

    protected customSuccessMessage: string | null = 'web.form.submit_success_profile';

    protected defMutation(model: ExtRecord<string | number>): MutationOptions {
        return {
            // Query
            mutation: gql`
                mutation Profile($input: ProfileInput) {
                    profile(identity: $input) {
                        status
                        message
                    }
                }
            `,
            // Parameters
            variables: {
                input: model,
            },
        };
    }

    created(): void {
        this.init<typeof MODEL>(MODEL);
        this.model = { ...this.model, ...this.profile };
        this.model.name = this.firstName;
        this.model.surname = this.surName;
    }

    protected beforeSubmitHook(model: ExtRecord<string | number>): void {
        delete model.email;
        delete model.emails;
        delete model.photos;
        delete model.provider;
        // eslint-disable-next-line no-underscore-dangle
        delete model._raw;
        // eslint-disable-next-line no-underscore-dangle
        delete model._json;
        delete model.displayName;
        delete model.type;
        delete model.id;
        delete model.profile;
        delete model.password;
    }

    protected async afterSubmitHook(): Promise<void> {
        this.edit = false;
        this.mutationAuth(await this.actionStatus());
    }
}
