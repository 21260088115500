/**
 *  Forgotten password model
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

export const reset = {
    password: '',
    uuid: '',
    token: null,
};

export default {
    email: '',
    token: null,
};
