import { RouteConfig } from 'vue-router';

import Dashboard from '@pages/products/dashboard/Index.vue';
import Detail from '@pages/products/detail/Index.vue';
import Confirmation from '@pages/products/confirmation/Index.vue';
import Ordered from '@pages/products/ordered/Index.vue';
import Header from '@components/page/Header.vue';
import Footer from '@/components/page/Footer.vue';
import Success from '@pages/products/confirmation/Success.vue';

const route: RouteConfig[] = [
    {
        path: '/products/dashboard',
        name: 'Dashboard',
        components: {
            default: Dashboard,
            header: Header,
            footer: Footer,
        },
        meta: {
            requiresAuth: true,
            header: [],
        },
    },
    {
        path: '/products/detail/:id',
        name: 'ProductDetail',
        props: {
            default: true,
        },
        components: {
            default: Detail,
            header: Header,
        },
        meta: {
            requiresAuth: true,
            header: [],
        },
    },
    {
        path: '/products/confirmation/:id',
        name: 'ProductConfirmation',
        props: {
            default: true,
        },
        components: {
            default: Confirmation,
            header: Header,
            footer: Footer,
        },
        meta: {
            requiresAuth: true,
            header: [],
        },
    },
    {
        path: '/products/activation',
        name: 'ProductActivation',
        props: {
            default: true,
        },
        components: {
            default: Success,
            header: Header,
        },
        meta: {
            requiresAuth: true,
            header: [],
        },
    },
    {
        path: '/products/ordered',
        name: 'YourProducts',
        components: {
            default: Ordered,
            header: Header,
            footer: Footer,
        },
        meta: {
            requiresAuth: true,
            header: [],
        },
    },
];

export default route;
