/**
 *  Main configuration object for all modules
 *  Here you must define routes, storage ect...
 *  @module modules
 *  @author Algotech <info@algotech.cz>
 *  @copyright 2021 Algotech a.s.
 */

/** DEFINE ROUTES FOR EACH MODULE * */
import demoModuleRouter from '@modules/demo-module/router';
/** END DEFINE ROUTES FOR EACH MODULE * */

/** DEFINE VUEX STORE * */
import demoModuleStore from '@modules/demo-module/store';

const router = [...demoModuleRouter];

const store = {
    demo: demoModuleStore,
};
/** END DEFINE VUEX STORE * */

export default {
    router,
    store,
};
